// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SIRE from "./sire/views/SIRE.js";
import * as React from "../libs/rescript-mithril/src/React.js";
import * as Sentry from "../libs/Sentry.js";
import * as Upload from "./uploads/Upload.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mithril from "../libs/rescript-mithril/src/Mithril.js";
import * as MixPanel from "../libs/MixPanel.js";
import * as ReactDOM from "../libs/rescript-mithril/src/ReactDOM.js";
import * as Settings from "./Settings.js";
import * as TSIsArea from "./shore/TSIsArea.js";
import * as TreeView from "./tree/views/TreeView.js";
import * as LevelView from "./tree/views/LevelView.js";
import * as Sire2View from "./sire/views/Sire2View.js";
import * as ListLayout from "./common/views/ListLayout.js";
import * as QuizWidget from "./tree/views/questions/QuizWidget.js";
import * as FindingList from "./tasks/views/FindingList.js";
import * as Vessels_Home from "./vessel/views/Vessels_Home.js";
import * as AnswerDetails from "./tree/views/AnswerDetails.js";
import * as Section_Image from "./vessel/views/Section_Image.js";
import * as State__Memory from "./state/State__Memory.js";
import * as Accounts_Login from "./accounts/views/Accounts_Login.js";
import * as Browser from "@sentry/browser";
import * as DevelopersArena from "./debug/DevelopersArena.js";
import * as QuestionPlanner from "./tree/QuestionPlanner.js";
import * as Vessel_Sections from "./vessel/views/Vessel_Sections.js";
import * as DateQuestionView from "./survey/views/DateQuestionView.js";
import * as InspectionEditor from "./survey/views/InspectionEditor.js";
import * as TextQuestionView from "./survey/views/TextQuestionView.js";
import * as Vessel_Dashboard from "./vessel/views/Vessel_Dashboard.js";
import * as DeficiencyDetails from "./tasks/views/DeficiencyDetails.js";
import * as IntQuestionWidget from "./tree/views/questions/IntQuestionWidget.js";
import * as NotificationsView from "./notifications/NotificationsView.js";
import * as DateQuestionWidget from "./tree/views/questions/DateQuestionWidget.js";
import * as Section_Components from "./vessel/views/Section_Components.js";
import * as TextQuestionWidget from "./tree/views/questions/TextQuestionWidget.js";
import * as Accounts_AddProfile from "./accounts/views/Accounts_AddProfile.js";
import * as IntegerQuestionView from "./survey/views/IntegerQuestionView.js";
import * as LevelSubmissionView from "./tree/views/LevelSubmissionView.js";
import * as NotApplicableWidget from "./tree/views/questions/NotApplicableWidget.js";
import * as PhotoQuestionWidget from "./tree/views/questions/PhotoQuestionWidget.js";
import * as Vessel_CompanyForms from "./vessel/views/Vessel_CompanyForms.js";
import * as YesNoQuestionWidget from "./tree/views/questions/YesNoQuestionWidget.js";
import * as Accounts_EditProfile from "./accounts/views/Accounts_EditProfile.js";
import * as Component_Procedures from "./vessel/views/Component_Procedures.js";
import * as MarkdownDocumentPage from "./markdown/views/MarkdownDocumentPage.js";
import * as RatingQuestionWidget from "./tree/views/questions/RatingQuestionWidget.js";
import * as OKNotOKQuestionWidget from "./tree/views/questions/OKNotOKQuestionWidget.js";
import * as CompanyFormSubmissions from "./company_forms/views/CompanyFormSubmissions.js";
import * as CompanyForm_Procedures from "./company_forms/views/CompanyForm_Procedures.js";
import * as SignatureQuestionWidget from "./tree/views/questions/SignatureQuestionWidget.js";
import * as SurveyInspectionPlanner from "./survey/views/SurveyInspectionPlanner.js";
import * as Vessel_Planned_Sections from "./vessel/views/Vessel_Planned_Sections.js";
import * as StartInspectionProcedure from "./survey/views/StartInspectionProcedure.js";
import * as WeightInfoQuestionWidget from "./tree/views/questions/WeightInfoQuestionWidget.js";
import * as Accounts_LandingPageVessel from "./accounts/views/Accounts_LandingPageVessel.js";
import * as Accounts_LoginWithPassword from "./accounts/views/Accounts_LoginWithPassword.js";
import * as Accounts_LandingPageProfile from "./accounts/views/Accounts_LandingPageProfile.js";
import * as MultipleDatesQuestionWidget from "./tree/views/questions/MultipleDatesQuestionWidget.js";
import * as MultipleTextsQuestionWidget from "./tree/views/questions/MultipleTextsQuestionWidget.js";
import * as SignatureInspectionProcedure from "./survey/views/SignatureInspectionProcedure.js";
import * as MultipleIntegersQuestionWidget from "./tree/views/questions/MultipleIntegersQuestionWidget.js";
import * as MultipleDatesTextsQuestionWidget from "./tree/views/questions/MultipleDatesTextsQuestionWidget.js";
import * as MultipleDatesYesNoQuestionWidget from "./tree/views/questions/MultipleDatesYesNoQuestionWidget.js";
import * as MultipleDatesIntegersQuestionWidget from "./tree/views/questions/MultipleDatesIntegersQuestionWidget.js";
import * as WeightMeasurementInspectionProcedure from "./survey/views/WeightMeasurementInspectionProcedure.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var set = vnode.attrs.set;
                return React.jsxs(ListLayout.make, {
                            children: [
                              ReactDOM.jsx("h4", {
                                    children: "Choose the planner"
                                  }),
                              ReactDOM.jsx("button", {
                                    children: "Stable Inspection Planner",
                                    className: "card card-shadow my-2 p-2",
                                    style: {
                                      cursor: "pointer",
                                      width: "100%"
                                    },
                                    onClick: (function (param) {
                                        set("Survey");
                                      })
                                  }),
                              ReactDOM.jsx("button", {
                                    children: "New Planner",
                                    className: "card card-shadow my-2 p-2",
                                    style: {
                                      cursor: "pointer",
                                      width: "100%"
                                    },
                                    onClick: (function (param) {
                                        set("Tree");
                                      })
                                  })
                            ],
                            title: "Filter",
                            showBackButton: true,
                            showSettings: true,
                            section: "planner"
                          });
              }));
}

var Selector = {
  make: make
};

function make$1() {
  return Mithril.view(Mithril.oninit(Mithril.component("Init"), (function (vnode) {
                    var match = vnode.state.data;
                    if (typeof match === "object") {
                      return ;
                    }
                    if (match !== "Init") {
                      return ;
                    }
                    var match$1 = Settings.FeatureFlags.newInspectionPlanner;
                    if (match$1 === "force") {
                      return Mithril.setState(vnode, {
                                  TAG: "Selected",
                                  _0: "Tree"
                                });
                    } else if (match$1 === "allow") {
                      return Mithril.setState(vnode, "Selecting");
                    } else {
                      return Mithril.setState(vnode, {
                                  TAG: "Selected",
                                  _0: "Survey"
                                });
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  if (match === "Init") {
                    return Mithril.empty;
                  } else {
                    return React.jsx(make, {
                                set: (function (selection) {
                                    Mithril.setState(vnode, {
                                          TAG: "Selected",
                                          _0: selection
                                        });
                                  })
                              });
                  }
                } else if (match._0 === "Survey") {
                  return React.jsx(SurveyInspectionPlanner.make, {});
                } else {
                  return React.jsx(QuestionPlanner.make, {});
                }
              }));
}

var InspectionPlannerRouter = {
  Selector: Selector,
  make: make$1
};

function withSentryReplay(fn) {
  return function (vnode) {
    Sentry.Replay.restart(Browser.getReplay());
    return fn(vnode);
  };
}

var routes = Js_dict.fromArray(MixPanel.withAnalytics([
          [
            "/",
            Vessels_Home.make
          ],
          [
            "/login",
            withSentryReplay(Accounts_Login.make)
          ],
          [
            "/login/password",
            Accounts_LoginWithPassword.make
          ],
          [
            "/landingpage/vessel",
            Accounts_LandingPageVessel.make
          ],
          [
            "/landingpage/profile",
            Accounts_LandingPageProfile.make
          ],
          [
            "/vessel/:vesselId",
            Vessel_Dashboard.make
          ],
          [
            "/vessel/:vesselId/company-forms/:formType",
            withSentryReplay(Vessel_CompanyForms.make)
          ],
          [
            "/vessel/:vesselId/level/:levelId",
            LevelView.make
          ],
          [
            "/vessel/:vesselId/tree/:kind",
            TreeView.make
          ],
          [
            "/vessel/:vesselId/sire/",
            withSentryReplay(SIRE.make)
          ],
          [
            "/vessel/:vesselId/sire/:levelId",
            Sire2View.make
          ],
          [
            "/vessel/:vesselId/planned",
            Vessel_Planned_Sections.make
          ],
          [
            "/vessel/:vesselId/:componentType",
            Vessel_Sections.make
          ],
          [
            "/section/:sectionId/image",
            Section_Image.make
          ],
          [
            "/section/:sectionId/:componentType",
            Section_Components.make
          ],
          [
            "/component/:componentId",
            Component_Procedures.make
          ],
          [
            "/procedure/:procedureId",
            StartInspectionProcedure.make
          ],
          [
            "/weight-measurement-procedure/:procedureId",
            WeightMeasurementInspectionProcedure.make
          ],
          [
            "/date-procedure/:procedureId",
            DateQuestionView.make
          ],
          [
            "/text-procedure/:procedureId",
            TextQuestionView.make
          ],
          [
            "/integer-procedure/:procedureId",
            IntegerQuestionView.make
          ],
          [
            "/signature-procedure/:procedureId",
            SignatureInspectionProcedure.make
          ],
          [
            "/inspection/:inspectionId/edit",
            InspectionEditor.make
          ],
          [
            "/deficiencies",
            FindingList.make
          ],
          [
            "/deficiencies/:activeTab",
            FindingList.make
          ],
          [
            "/deficiency/:deficiencyId",
            DeficiencyDetails.make
          ],
          [
            "/company-form/:formId",
            CompanyFormSubmissions.make
          ],
          [
            "/company-form/:formId/submission/:submissionId",
            CompanyForm_Procedures.make
          ],
          [
            "/inspection-planner",
            make$1
          ],
          [
            "/notifications",
            NotificationsView.make
          ],
          [
            "/user-profiles",
            Accounts_LandingPageProfile.make
          ],
          [
            "/user-addprofile/:vesselId",
            Accounts_AddProfile.make
          ],
          [
            "/user-editprofile/:vesselId/:profileId",
            Accounts_EditProfile.make
          ],
          [
            "/upload",
            withSentryReplay(Upload.make)
          ],
          [
            "/review-new-inspections",
            Upload.make
          ],
          [
            "/document/:documentId",
            MarkdownDocumentPage.make
          ],
          [
            "/!debug",
            DevelopersArena.make
          ],
          [
            "/vessel/:vesselId/level-submission/:levelId/",
            LevelSubmissionView.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/submission/",
            LevelView.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-bool",
            YesNoQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-ok-or-not",
            OKNotOKQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-date",
            DateQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-text",
            TextQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-int",
            IntQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-rating",
            RatingQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-photo",
            PhotoQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-signature",
            SignatureQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-multiple-dates",
            MultipleDatesQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-multiple-dates-yes-no",
            MultipleDatesYesNoQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-multiple-dates-integers",
            MultipleDatesIntegersQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-multiple-dates-texts",
            MultipleDatesTextsQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-multiple-integers",
            MultipleIntegersQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-multiple-texts",
            MultipleTextsQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-quiz",
            QuizWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/edit-weight-info",
            WeightInfoQuestionWidget.Editor.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/question/:questionId/not-applicable",
            NotApplicableWidget.make
          ],
          [
            "/vessel/:vesselId/level/:levelId/answer/:answerId/details",
            AnswerDetails.make
          ],
          [
            "/tsi/",
            withSentryReplay(TSIsArea.make)
          ]
        ], (function () {
            var match = State__Memory.Current.get();
            return {
                    user: match.user,
                    vessel: match.vessel,
                    profile: match.profile
                  };
          })));

export {
  InspectionPlannerRouter ,
  withSentryReplay ,
  routes ,
}
/* routes Not a pure module */
