// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LevelStatus from "../../tree/views/LevelStatus.js";

async function getSireFiltersFromUrl(vesselId) {
  var filters = LevelStatus.getFiltersFromUrl();
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.sire2VettingPeriods = {
          TAG: "Limit",
          _0: 1,
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        }, newrecord));
  var sire2VettingPeriodId = Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match.sire2VettingPeriods), (function (v) {
          return v.id;
        }));
  var answerFilter = function (answers) {
    return Curry._2(Prelude.$$Array.keep, answers, (function (a) {
                  return Caml_obj.equal(a.sire2_vetting_period_id, sire2VettingPeriodId);
                }));
  };
  return {
          answerFilter: answerFilter,
          questionFilter: filters.questionFilter,
          questionStatusFilter: filters.questionStatusFilter,
          childrenFilter: filters.childrenFilter
        };
}

async function getSire2LevelStatus(vesselId, levelId) {
  var filters = await getSireFiltersFromUrl(vesselId);
  return await LevelStatus.LevelState.getState(vesselId, levelId, filters);
}

async function getSire2CompletionStatus(vesselId, levelId) {
  var filters = await getSireFiltersFromUrl(vesselId);
  return await LevelStatus.LevelState.getLevelCompletionStatus(vesselId, levelId, filters);
}

export {
  getSireFiltersFromUrl ,
  getSire2LevelStatus ,
  getSire2CompletionStatus ,
}
/* Query Not a pure module */
